import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FiArrowRight, FiCheck, FiStar } from 'react-icons/fi';
import bg1 from './assets/images/1.webp';
import bg2 from './assets/images/2.webp';
import bg3 from './assets/images/3.webp';

function App() {
  const [activeTab, setActiveTab] = useState('home');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const response = await fetch('https://ai-refinery.com/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      
      if (result.success) {
        setSubmitStatus({
          type: 'success',
          message: 'Thank you for contacting us! We will get back to you soon.',
        });
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        throw new Error(result.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Contact form error:', error);
      setSubmitStatus({
        type: 'error',
        message: error.message || 'Failed to send message. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'home':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg1} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto px-4 relative z-10">
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="py-20 text-center relative overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 animate-pulse"></div>
                <div className="container mx-auto px-4 relative z-10">
                  <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
                    Next-Gen AI Solutions
                  </h1>
                  <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                    Empowering businesses with cutting-edge AI technology and expert solutions
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        );

      case 'agents':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg2} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto px-4 relative z-10">
              <SectionHeader 
                title="AI Agent Solutions" 
                subtitle={<GradientSubtitle text="Intelligent automation solutions powered by advanced AI technology" />}
              />
              {/* Benefits Section */}
              <div className="mb-20">
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Benefits of Custom AI Agent Development</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Enhanced Efficiency</h4>
                    <p className="text-gray-600">
                      Automate repetitive tasks and streamline workflows with intelligent AI agents.
                    </p>
                  </div>
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">24/7 Availability</h4>
                    <p className="text-gray-600">
                      Provide round-the-clock service with AI agents that never sleep.
                    </p>
                  </div>
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Cost Reduction</h4>
                    <p className="text-gray-600">
                      Significantly reduce operational costs while maintaining high service quality.
                    </p>
                  </div>
                </div>
              </div>

              {/* Products Section */}
              <div className="mb-20">
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Our AI Agent Products</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Customer Service Agent</h4>
                    <p className="text-gray-600 mb-4">
                      AI-powered support agents that handle customer inquiries 24/7.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Natural language processing
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Multi-language support
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Seamless handoff to humans
                      </li>
                    </ul>
                  </div>
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Sales Assistant</h4>
                    <p className="text-gray-600 mb-4">
                      AI agents that qualify leads and assist in the sales process.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Lead qualification
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Product recommendations
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Meeting scheduling
                      </li>
                    </ul>
                  </div>
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">HR Assistant</h4>
                    <p className="text-gray-600 mb-4">
                      Streamline HR processes with intelligent AI automation.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Resume screening
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Interview scheduling
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Employee onboarding
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Services Section */}
              <div>
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">AI Agent Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Custom Development</h4>
                    <p className="text-gray-600 mb-4">
                      Tailored AI agents designed specifically for your business needs.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Requirements analysis
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Custom integration
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Ongoing support
                      </li>
                    </ul>
                  </div>
                  <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                    <h4 className="text-xl font-bold text-gray-800 mb-3">Training & Deployment</h4>
                    <p className="text-gray-600 mb-4">
                      Comprehensive training and deployment services for AI agents.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Model training
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        System integration
                      </li>
                      <li className="flex items-center">
                        <FiCheck className="text-green-500 mr-2" />
                        Performance monitoring
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'rag':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg3} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto px-4 relative z-10">
              <SectionHeader 
                title="RAG Solutions" 
                subtitle={<GradientSubtitle text="Unlock the power of Retrieval-Augmented Generation" />}
              />
              
              {/* What is RAG Section */}
              <div className="mb-20">
                <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">What is RAG?</h3>
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                  <p className="text-gray-600 text-lg mb-6">
                    RAG (Retrieval-Augmented Generation) is an advanced AI technology that combines information retrieval with generative AI models. 
                    It enables systems to fetch relevant context from vast data repositories and generate precise, context-aware responses.
                  </p>
                  
                  {/* How RAG Works */}
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
                    <div className="bg-blue-50 p-6 rounded-lg">
                      <div className="text-blue-600 text-xl font-bold mb-3">1. Retrieval</div>
                      <p className="text-gray-600">Searches and fetches relevant information from your knowledge base</p>
                    </div>
                    <div className="bg-blue-50 p-6 rounded-lg">
                      <div className="text-blue-600 text-xl font-bold mb-3">2. Augmentation</div>
                      <p className="text-gray-600">Enhances the context with retrieved information</p>
                    </div>
                    <div className="bg-blue-50 p-6 rounded-lg">
                      <div className="text-blue-600 text-xl font-bold mb-3">3. Generation</div>
                      <p className="text-gray-600">Produces accurate, contextually relevant responses</p>
                    </div>
                  </div>
                </div>

                {/* Advantages Section */}
                <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Key Advantages</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <div className="text-blue-600 text-xl font-bold mb-3">Improved Accuracy</div>
                    <p className="text-gray-600">Generates responses based on verified information from your data</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <div className="text-blue-600 text-xl font-bold mb-3">Context-Aware</div>
                    <p className="text-gray-600">Understands and maintains context throughout conversations</p>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <div className="text-blue-600 text-xl font-bold mb-3">Reduced Hallucinations</div>
                    <p className="text-gray-600">Minimizes incorrect or fabricated information in responses</p>
                  </div>
                </div>

                {/* RAG-Based Products */}
                <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Enterprise RAG Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Smart Customer Support</h4>
                    <p className="text-gray-600 mb-4">
                      AI chatbots that provide real-time, accurate answers by retrieving data from your knowledge base.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Customer service teams</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Knowledge Management</h4>
                    <p className="text-gray-600 mb-4">
                      AI tools that retrieve and summarize enterprise-wide data from documents, emails, and databases.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Large organizations</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Legal Assistant</h4>
                    <p className="text-gray-600 mb-4">
                      RAG-powered tools for retrieving and interpreting legal documents and compliance standards.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Law firms</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">E-learning Platform</h4>
                    <p className="text-gray-600 mb-4">
                      Custom learning platforms that fetch relevant study materials and generate personalized content.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Educational institutions</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Healthcare Assistant</h4>
                    <p className="text-gray-600 mb-4">
                      Tools to fetch medical knowledge from research papers and provide reliable responses.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Healthcare providers</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">E-commerce Helper</h4>
                    <p className="text-gray-600 mb-4">
                      Chatbots and recommendation systems using RAG to fetch detailed product information.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Online retailers</div>
                  </div>
                </div>

                {/* Services Section */}
                <h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Our RAG Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Custom Development</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Tailored RAG solutions for your industry</li>
                      <li>• Integration with existing systems</li>
                      <li>• Knowledge base optimization</li>
                    </ul>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Ongoing Support</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Model fine-tuning and updates</li>
                      <li>• Performance monitoring</li>
                      <li>• Technical maintenance</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'data':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg1} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto px-4 relative z-10">
              <SectionHeader 
                title="AI Data Engineering" 
                subtitle={<span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Unlock the power of data for your business with our AI data engineering solutions</span>}
              />
              
              {/* Data Processing Section */}
              <div className="mb-20">
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Data Processing Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Automated Data Cleaning</h4>
                    <p className="text-gray-600 mb-4">
                      A system to clean, preprocess, and normalize raw datasets automatically, ensuring high-quality data for analysis or model training.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: ETL pipeline optimization</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Real-time Processing</h4>
                    <p className="text-gray-600 mb-4">
                      A tool for real-time data ingestion, transformation, and enrichment for large-scale applications.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Financial services, IoT</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Data Aggregation</h4>
                    <p className="text-gray-600 mb-4">
                      Combines data from multiple sources (APIs, databases, web scrapers) into a unified, structured format.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Marketing analytics</div>
                  </div>
                </div>

                {/* Data Collection Section */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Data Collection Tools</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Web Data Scraping</h4>
                    <p className="text-gray-600 mb-4">
                      A scalable tool for extracting data from websites, APIs, or public datasets while adhering to legal policies.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: E-commerce analytics</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">IoT Data Aggregator</h4>
                    <p className="text-gray-600 mb-4">
                      A system for collecting and synchronizing IoT sensor data across multiple devices in real time.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Smart automation</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Crowdsourcing Platform</h4>
                    <p className="text-gray-600 mb-4">
                      A mobile or web app where users can contribute data (e.g., surveys, images, location data).
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Research organizations</div>
                  </div>
                </div>

                {/* Data Creation Section */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Data Creation & Synthesis</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Synthetic Data Generation</h4>
                    <p className="text-gray-600 mb-4">
                      Create synthetic datasets using generative AI to augment real data for training models.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: AI model training</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Automated Labeling</h4>
                    <p className="text-gray-600 mb-4">
                      AI-assisted tools for labeling datasets with high precision and scalability.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Computer vision, NLP</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Scenario Simulation</h4>
                    <p className="text-gray-600 mb-4">
                      A tool for simulating hypothetical scenarios for testing algorithms or systems.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Autonomous systems</div>
                  </div>
                </div>

                {/* Vector Database Section */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Vector Database Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Enterprise Vector DB</h4>
                    <p className="text-gray-600 mb-4">
                      A high-performance, scalable database for storing and querying embeddings efficiently.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Real-time similarity search</li>
                      <li>• Efficient embedding storage</li>
                      <li>• Scalable architecture</li>
                    </ul>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Embedding Management</h4>
                    <p className="text-gray-600 mb-4">
                      A system to organize, version, and optimize embeddings for enterprise use.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Version control for embeddings</li>
                      <li>• Performance optimization</li>
                      <li>• Integration support</li>
                    </ul>
                  </div>
                </div>

                {/* Services Section */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Our Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Custom Development</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• ETL pipeline development</li>
                      <li>• Data annotation services</li>
                      <li>• Format conversion tools</li>
                    </ul>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Integration Services</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• API integration</li>
                      <li>• Vector DB implementation</li>
                      <li>• Embedding optimization</li>
                    </ul>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Consulting</h4>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Data strategy planning</li>
                      <li>• Architecture design</li>
                      <li>• Performance optimization</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'finetune':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg2} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto px-4 relative z-10">
              <SectionHeader 
                title="LLM Fine-Tuning Solutions" 
                subtitle={<GradientSubtitle text="Unlock the full potential of your LLM with our fine-tuning solutions" />}
              />
              
              {/* Products Section */}
              <div className="mb-20">
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Fine-Tuned LLM Products</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Domain-Specific LLM</h4>
                    <p className="text-gray-600 mb-4">
                      Fine-tuned LLMs optimized for specific industries like legal, healthcare, and finance.
                    </p>
                    <div className="text-sm text-gray-600">
                      Perfect for:
                      <ul className="mt-2 space-y-1">
                        <li>• Legal firms needing contract Q&A</li>
                        <li>• Healthcare companies requiring medical terminology</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Conversational AI</h4>
                    <p className="text-gray-600 mb-4">
                      Pre-trained chat models fine-tuned to handle customer support, onboarding, or enterprise workflows.
                    </p>
                    <div className="text-sm text-gray-600">
                      Perfect for:
                      <ul className="mt-2 space-y-1">
                        <li>• E-commerce customer support</li>
                        <li>• SaaS user onboarding</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Enhanced Generative AI</h4>
                    <p className="text-gray-600 mb-4">
                      Fine-tuned LLMs for creative tasks like content creation, marketing copy, or code generation.
                    </p>
                    <div className="text-sm text-gray-600">
                      Perfect for:
                      <ul className="mt-2 space-y-1">
                        <li>• Marketing agencies</li>
                        <li>• Software development teams</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Multilingual Models</h4>
                    <p className="text-gray-600 mb-4">
                      LLMs fine-tuned for multilingual tasks, such as translation, transcription, and localization.
                    </p>
                    <div className="text-sm text-gray-600">
                      Perfect for:
                      <ul className="mt-2 space-y-1">
                        <li>• Global enterprises</li>
                        <li>• Media localization</li>
                      </ul>
                    </div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Enterprise Knowledge Base</h4>
                    <p className="text-gray-600 mb-4">
                      LLMs fine-tuned with enterprise data for internal search, training, and decision support.
                    </p>
                    <div className="text-sm text-gray-600">
                      Perfect for:
                      <ul className="mt-2 space-y-1">
                        <li>• Corporate knowledge management</li>
                        <li>• Customer service teams</li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Services Section */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Fine-Tuning Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Custom LLM Fine-Tuning</h4>
                    <p className="text-gray-600 mb-4">
                      Fine-tuning LLMs like GPT-4, LLaMA, or Falcon on proprietary datasets.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li>• Data preparation and cleaning</li>
                      <li>• Model selection and optimization</li>
                      <li>• Performance evaluation</li>
                    </ul>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Deployment Solutions</h4>
                    <p className="text-gray-600 mb-4">
                      Secure deployment options for fine-tuned models in various environments.
                    </p>
                    <ul className="space-y-2 text-gray-600">
                      <li>• On-premise deployment</li>
                      <li>• Cloud integration</li>
                      <li>• Edge device optimization</li>
                    </ul>
                  </div>
                </div>

                {/* Specialized Offerings */}
                <h3 className="text-2xl font-bold mb-8 bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Specialized Solutions</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">RAG Integration</h4>
                    <p className="text-gray-600 mb-4">
                      Fine-tuned models integrated with Retrieval-Augmented Generation for context-aware responses.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Dynamic knowledge bases</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Edge Deployment</h4>
                    <p className="text-gray-600 mb-4">
                      Model compression and optimization for mobile and edge devices.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Mobile applications</div>
                  </div>
                  <div className="bg-white p-6 rounded-lg shadow-lg">
                    <h4 className="text-xl font-bold text-blue-600 mb-3">Multi-modal Fine-Tuning</h4>
                    <p className="text-gray-600 mb-4">
                      Enhancing models for tasks involving text, images, and audio inputs.
                    </p>
                    <div className="text-sm text-gray-600">Perfect for: Media companies</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'contact':
        return (
          <div className="py-20 relative">
            <div className="absolute inset-0 z-0">
              <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
              <img src={bg3} alt="" className="w-full h-full object-cover opacity-30" />
            </div>
            <div className="container mx-auto max-w-4xl px-4 relative z-10">
              <SectionHeader 
                title="Get in Touch" 
                subtitle={<GradientSubtitle text="Let's discuss how we can help transform your business with AI" />}
              />
              <div className="text-center mb-8">
                <p className="text-lg text-gray-300">
                  You can also reach us directly at{' '}
                  <a 
                    href="mailto:airefinerybot@gmail.com" 
                    className="text-blue-400 hover:text-blue-300 transition-colors font-medium"
                  >
                    airefinerybot@gmail.com
                  </a>
                </p>
              </div>
              <div className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300">
                <motion.form 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="max-w-md mx-auto space-y-6"
                  onSubmit={handleSubmit}
                >
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full bg-gray-800/50 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="w-full bg-gray-800/50 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="relative">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows="5"
                      className="w-full bg-gray-800/50 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-medium py-3 px-6 rounded-lg hover:opacity-90 transition-opacity focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-900 disabled:opacity-50"
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </motion.button>
                  {submitStatus && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className={`text-center mt-4 ${
                        submitStatus.type === 'success' ? 'text-green-400' : 'text-red-400'
                      }`}
                    >
                      {submitStatus.message}
                    </motion.div>
                  )}
                </motion.form>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const GradientSubtitle = ({ text }) => (
    <span className="bg-gradient-to-r from-cyan-400 via-blue-500 to-purple-600 bg-clip-text text-transparent">
      {text}
    </span>
  );

  function ProductCard({ title, description, features, icon }) {
    return (
      <motion.div
        whileHover={{ y: -5, scale: 1.02 }}
        className="bg-gray-50/90 backdrop-blur-lg rounded-xl p-6 shadow-lg border border-white/20 hover:border-blue-500/50 transition-all duration-300"
      >
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center">
            {icon && <span className="text-blue-600 mr-3">{icon}</span>}
            <h4 className="text-xl font-bold text-gray-800">
              {title}
            </h4>
          </div>
          <div className="bg-blue-100 p-2 rounded-lg">
            <FiStar className="text-blue-600" />
          </div>
        </div>
        <p className="text-gray-600 mb-4">{description}</p>
        {features && (
          <ul className="space-y-2 mb-4">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center text-gray-600">
                <FiCheck className="text-green-500 mr-2 flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        )}
        <motion.button
          whileHover={{ x: 5 }}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-700 transition-colors"
        >
          Learn more <FiArrowRight className="ml-2" />
        </motion.button>
      </motion.div>
    );
  }

  function SectionHeader({ title, subtitle }) {
    return (
      <div className="text-center mb-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
        >
          {title}
        </motion.h2>
        {subtitle && (
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 max-w-3xl mx-auto"
          >
            {subtitle}
          </motion.p>
        )}
        <div className="w-24 h-1 bg-gradient-to-r from-blue-500 to-purple-500 mx-auto mt-8 rounded-full"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white">
      {/* Navigation */}
      <nav className="bg-black/30 backdrop-blur-lg border-b border-white/10 sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text"
            >
              AI Refinery
            </motion.div>
            <div className="flex space-x-1">
              {['home', 'agents', 'rag', 'data', 'finetune', 'contact'].map((tab) => (
                <motion.button
                  key={tab}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                    activeTab === tab
                      ? 'bg-blue-500/20 text-blue-400 font-medium'
                      : 'hover:bg-white/5 text-gray-300'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'rag' ? 'RAG' : 
                   tab === 'agents' ? 'AI Agents' :
                   tab.charAt(0).toUpperCase() + tab.slice(1)}
                </motion.button>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="flex-grow relative">
        <div className="fixed inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 opacity-90" />
          <img src={bg1} alt="" className="w-full h-full object-cover opacity-30" />
        </div>
        <div className="relative z-10">
          {renderTabContent()}
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-black/30 backdrop-blur-lg border-t border-white/10 py-6 mt-auto relative z-10">
        <div className="container mx-auto px-4 text-center">
          <p className="text-gray-400">&copy; {new Date().getFullYear()} ai-refinery. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
